.unknownPageContainer {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 20px;
}

.unknownPageTitle {
    font-size: 64px;
    font-weight: var(--text-wide);
    color: var(--main-black); 
}

.unknownPageReturnLink {
    font-size: 40px;
    font-weight: var(--text-semiwide);
    color: cornflowerblue;
}