.aboutUsContainer {
    padding-top: 80px;
    padding-bottom: 80px;
    background-image: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 30%, rgba(0,0,0,0) 70%, rgba(0,0,0,1) 100%), url('../images/background/about-bg.jpg');
    background-size: cover;
}

.aboutUsTopInfo {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.aboutUsTitle {
    width: 40%;
    color: var(--main-white);
    font-size: 64px;
    font-weight: var(--text-wide);
}

.aboutUsDescription {
    text-align: justify;
    width: 60%;
    color: var(--main-white);
    font-size: 24px;
    font-weight: var(--text-normal);
}

.aboutUsCards {
    margin-top: 195px;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(2, 1fr);
    column-gap: 0px;
    row-gap: 100px; 
}

.aboutUsCard:nth-child(1) { grid-area: 1 / 1 / 2 / 2; }
.aboutUsCard:nth-child(2) { grid-area: 1 / 3 / 2 / 4; }
.aboutUsCard:nth-child(3) { grid-area: 1 / 5 / 2 / 6; }
.aboutUsCard:nth-child(4) { grid-area: 2 / 2 / 3 / 3; }
.aboutUsCard:nth-child(5) { grid-area: 2 / 4 / 3 / 5; } 

.aboutUsCard {
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    background-color: transparent;
    width: 100%;
    text-align: center;
}

.aboutUsCard img {
    max-width: 128px;
}

.aboutUsCardTitle {
    font-size: 24px;
    color: var(--main-white);
    font-weight: var(--text-semiwide);
}

.aboutUsCardInfo {
    font-size: 20px;
    text-align: center;
    color: var(--main-white);
    font-weight: var(--text-normal);
}

/* adaptive 1440px */

@media screen and (max-width: 1440px) {

    .aboutUsTitle {
        width: 40%;
    }

    .aboutUsDescription {
        width: 60%;
    }

    .aboutUsCardTitle {
        font-size: 20px;
    }

    .aboutUsCardInfo {
        font-size: 16px;
    }

    .aboutUsCard img {
        max-width: 96px;
    }
}

@media screen and (max-width: 1240px) {
    .aboutUsCards {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(3, 1fr);
        column-gap: 40px;
        row-gap: 100px;
    }

    .aboutUsCard:nth-child(1) { grid-area: 1 / 1 / 2 / 3; }
    .aboutUsCard:nth-child(2) { grid-area: 1 / 3 / 2 / 5; }
    .aboutUsCard:nth-child(3) { grid-area: 2 / 1 / 3 / 3; }
    .aboutUsCard:nth-child(4) { grid-area: 2 / 3 / 3 / 5; }
    .aboutUsCard:nth-child(5) { grid-area: 3 / 2 / 4 / 4; } 
}

/* adaptive 1024px */ 
@media screen and (max-width: 1024px) {
    .aboutUsTopInfo {
        display: flex;
        flex-direction: column;
        gap: 40px;
    }

    .aboutUsTitle {
        width: auto;
    }

    .aboutUsDescription {
        width: 100%;
    }


}

/* adaptive 810px */

@media screen and (max-width: 810px) {

    .aboutUsContainer {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .aboutUsTitle {
        font-size: 32px;
    }

    .aboutUsDescription {
        font-size: 16px;
    }

    .aboutUsCards {
        margin-top: 40px;
    }
}

/* adaptive 480px */ 
@media screen and (max-width: 480px) {
    .aboutUsCard img {
        max-width: 80px;
    }

    .aboutUsCardTitle {
        font-size: 18px;
    }

    .aboutUsCardInfo {
        font-size: 14px;
    }
}