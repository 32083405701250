.stackContainer {
    position: relative;
    padding-top: 120px;
    padding-bottom: 120px;
    background-image: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 30%, rgba(0,0,0,0) 70%, rgba(0,0,0,1) 100%), url('../images/background/stack-bg2.png');
    background-size: cover;
    overflow: hidden;
}

.stackOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.4);
    background-image: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 30%, rgba(0,0,0,0) 70%, rgba(0,0,0,1) 100%);
    z-index: 1;
}

.stackBgVideo {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 0;
}

@-moz-document url-prefix() {
    .stackOverlay {
        display: none;
    }

    .stackBgVideo {
        display: none;
    }
}

.stackTitle {
    position: relative;
    color: var(--main-white);
    font-size: 64px;
    margin-bottom: 24px;
    font-weight: var(--text-wide);
    z-index: 2;
}

.stackRow {
    position: relative;
    display: flex;
    flex-direction: row;
    padding-top: 40px;
    padding-bottom: 40px;
    border-bottom: 3px solid var(--main-white);
    align-items: flex-start;
    color: var(--main-white);
    z-index: 2;
}

.stackRowTitle {
    width: 50%;
    font-size: 40px;
    font-weight: var(--text-wide);
}

.stackRowContent {
    width: 50%;
}

.stackRowContent p {
    font-family: 'IBM Plex Mono', sans-serif;
    font-size: 24px;
    font-weight: var(--text-normal);
}

@media screen and (max-width: 1440px) {
    .stackRowTitle {
        width: 40%;
    }

    .stackRowContent {
        width: 60%;
    }
}

@media screen and (max-width: 1024px) {
    .stackRowTitle {
        font-size: 32px;
    }
    .stackRowContent p {
        font-size: 18px;
    }
}

@media screen and (max-width: 810px) {

    .stackContainer {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .stackTitle {
        font-size: 32px;
    }

    .stackRowTitle {
        font-size: 24px;
    }

    .stackRowContent p {
        font-size: 14px;
    }
}

@media screen and (max-width: 600px) {

    .stackRow {
        display: flex;
        flex-direction: column;
        gap: 12px;
        padding-bottom: 10px;
        border-bottom: 1px solid var(--main-white)
    }

    .stackRowTitle {
        width: auto;
    }

    .stackRowContent {
        width: auto;
    }

    .stackRowContent p {
        line-height: 150%;
    }
}
