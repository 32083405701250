.headerNav {
    padding-top: 20px;
    width: 100%;
    display: flex;
    height: 100px;
    align-items: center;
    justify-content: space-between;
}

.navLogo img {
    height: 80px;
}

.navLink {
    color: var(--main-white);
    font-weight: var(--text-normal);
    font-size: 24px;
    display: block;
    position: relative;
    padding: 0.2em 0;
    overflow: hidden;
    text-decoration: none;
}

.navLink:hover {
    cursor: pointer;
}

.navLink::before {
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    height: 3px;
    bottom: 0;
    left: 0;
    background-color: #7100d0;
    transform: translateX(-100%);
    transition: transform 0.3s ease;
}

.navLink:hover::before {
    transform: translateX(0);
  }

  /* adaptive 810px */

  @media screen and (max-width: 768px) {
    .navLink {
        font-size: 16px;
    }

    .navLogo img{
        height: 40px;
    }
  }

  /* adaptive 480px */

  @media screen and (max-width: 480px) {
    .navLink {
        font-size: 14px;
    }
  }
  