.contactUsContainer {
    padding-top: 80px;
    padding-bottom: 120px;
    background-color: var(--main-black);
    background-image: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 40%, rgba(0,0,0,0) 90%, rgba(0,0,0,1) 100%), url('../images/background/contact-bg-2.png');
    background-size: cover;
    background-position: center center;
}

.contactUsTitle {
    font-size: 64px;
    color: var(--main-white);
    font-weight: var(--text-wide);
    position: relative;
    z-index: 3;
}

.contactUsSubtitle {
    font-size: 24px;
    color: var(--main-white);
    font-weight: var(--text-normal);
    position: relative;
    font-family: 'IBM Plex Mono', sans-serif;
    z-index: 3;
}

.contactUsContentWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    padding: 100px 0;
    width: 100%;
}

.contactUsContentLeft {
    position: relative;
}

.contactUsContentLeft video {
    position: absolute;
    top: -250px;
    left: 0;
    width: 700px;
    opacity: 0.8;
    z-index: 1;
}

.contactUsContentLeft img {
    position: absolute;
    top: -150%;
    left: 0;
    width: 50vw;
    max-width: 700px;
    opacity: 0.8;
    z-index: 1;
}

.contactUsContentRight {
    backdrop-filter: blur(2px);
}

.contactUsForm {
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.contactUsForm input[type="text"],
.contactUsForm input[type="tel"],
.contactUsForm input[type="email"],
.contactUsForm textarea {
    background-color: transparent;
    border: none;
    border-bottom: 2px solid var(--main-white);
    color: var(--main-white);
    outline: none;
    font-size: 24px;
    width: 400px;
    font-weight: var(--text-normal);
    padding: 5px 10px;
    flex-grow: 0;
    flex-shrink: 1;
    font-family: 'IBM Plex Mono', sans-serif;
}

.contactUsForm textarea {
    height: 47px;
}

.contactUsForm input[type="submit"] {
    margin-top: 20px;
    background-color: black;
    border: 2px solid var(--main-white);
    color: var(--main-white);
    border-radius: 15px;
    font-size: 24px;
    padding: 5px 20px 10px 20px;
    width: 50%;
    font-weight: var(--text-semiwide);
    font-family: 'IBM Plex Mono', sans-serif;
    transition: all 0.4s;
}

.contactUsForm input[type="submit"]:hover {
    background-color: var(--main-white);
    color: var(--main-black);
    cursor: pointer;
}

@media screen and (max-width: 1100px) {
    .contactUsContentLeft img {
        top: -100%;
    }
}

@media screen and (max-width: 1000px) {
    .contactUsContentWrapper {
        align-items: flex-start;
    }

    .contactUsContentLeft img {
        top: -10%;
        width: 45vw;
    }

    .contactUsTitle {
        font-size: 32px;
    }

    .contactUsSubtitle {
        font-size: 16px;
    }

    .contactUsForm {
        gap: 24px;
    }

    .contactUsForm input[type="submit"] {
        font-size: 14px;
        width:fit-content;
        padding: 14px 15px;
        margin-top: 0;
    }


}

@media screen and (max-width: 810px) {

    .contactUsContainer {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .contactUsContentWrapper {
        padding: 20px 0px;
    }

    .contactUsContentLeft img {
        max-width: 256px;
    }

    .contactUsForm input[type="text"],
    .contactUsForm input[type="tel"],
    .contactUsForm input[type="email"],
    .contactUsForm textarea {
        font-size: 16px;
        width: 100%;
    }

        .contactUsContentRight {
        width: 60%;
    }
    
    br {
        display: none;
    }
}

@media screen and (max-width: 600px) {
    .contactUsContentWrapper {
        flex-direction: column;
        gap: 24px;
    }

    .contactUsContentLeft,
    .contactUsContentRight {
        width: 100%;
    }

    .contactUsContentLeft img {
        display: none;
    }
}