.scrollButton {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 5px;
    bottom: 50px;
    z-index: 100;
    border-radius: 50%;
    padding: 15px;
    background-image: radial-gradient(circle at center, rgba(56, 36, 103, 0.8) 0%, rgba(0,0,0,0) 80%);
    opacity: 0.7;
    transition: all 0.3s;
}

.scrollButtonImgOverlay {
    filter: blur(10px)
}

.scrollButton img {
    width: 40px;
    transform: rotate(180deg);
}

.scrollButton:hover {
    scale: 1.1;
    cursor: pointer;
    opacity: 1;
}