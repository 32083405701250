.servicesContainer {
    width: 100%;
    padding-top: 80px;
    background-image: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 40%, rgba(0,0,0,0) 60%, rgba(0,0,0,1) 100%), url('../images/background/products-bg2.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    box-shadow: inset 0 0 0 1000px rgba(0,0,0, 0.2);
    padding-bottom: 80px;
}

.servicesTitle {
    font-size: 64px;
    color: var(--main-white);
    font-weight: var(--text-wide);
}

.servicesSubtitle {
    margin-top: 60px;
    font-size: 24px;
    font-family: 'IBM Plex Mono';
    color: var(--main-white);
    font-weight: var(--text-normal);
    text-align: justify;
}

.servicesCards {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 30px;
    row-gap: 30px;
    margin-top: 64px;
}

.servicesTitle:last-child {
    margin-top: 80px;
}

.servicesCard {
    background-color: rgba(0, 0, 0, 0.4);
    border: 2px solid var(--main-white);
    color: var(--main-white);
    padding: 32px;
    transition: all 0.4s;
    transition-timing-function: ease;
    border-radius: 20px;
}

#serviceCard-7,
#serviceCard-8,
#serviceCard-9 {
    text-align: justify;
}

.servicesCardActive {
    background-color: var(--main-white);
    background-image: radial-gradient( circle at top right, rgba(113, 0, 208,0.20), transparent 20% );
    scale: 1.05;
}

.servicesCardActive .servicesCardTitle,
.servicesCardActive .servicesCardContent {
    color: var(--main-black);
}

.servicesCard:hover {
    background-color: var(--main-white);
    background-image: radial-gradient( circle at top right, rgba(113, 0, 208,0.20), transparent 20% );
    scale: 1.05;
}

.servicesCard:hover .servicesCardTitle,
.servicesCard:hover .servicesCardContent {
    color: var(--main-black);
}

.servicesCardTitle {
    color: var(--main-white);
    font-size: 40px;
    font-weight: var(--text-semiwide);
    line-height: 125%;
    letter-spacing: -1px;
}

.servicesCardContent {
    color: var(--main-white);
    font-size: 24px;
    margin-top: 40px;
    font-weight: var(--text-normal);
    font-family: 'IBM Plex Mono', sans-serif;
}

.servicesContactButton {
    border-radius: 30px;
    border: none;
    background-color: var(--main-black);
    font-size: 24px;
    color: var(--main-white);
}

.servicesContactButton:hover {
    background-color: var(--secondary-black);
    cursor: pointer;
}

/* adaptive 1440px */

@media screen and (max-width: 1440px) {
    .servicesCards {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 30px;
        row-gap: 30px;
        margin-top: 64px;
    }

    .servicesCardContent {
        margin-top: 24px;
    }
}

/* adaptive 1024px */

@media screen and (max-width: 1024px) {

    .servicesTitle {
        font-size: 32px;
    }
    
    .servicesCards {
        column-gap: 20px;
    }

    .servicesCardTitle {
        letter-spacing: 0px;
    }
    
    .servicesCard {
        padding: 12px 16px;
    }

    .servicesCardTitle {
        font-size: 32px;
    }
}

@media screen and (max-width: 810px) {

    .servicesContainer {
        padding-top: 20px;
        padding-bottom: 20px;
    }
    
    .servicesSubtitle {
        font-size: 16px;
        margin-top: 24px;
    }

    .servicesCards {
        margin-top: 40px;
    }
    
    .servicesCardTitle {
        font-size: 24px;
    }

    .servicesCardContent {
        font-size: 14px;
    }
}

@media screen and (max-width: 600px) {
    .servicesCards {
        grid-template-columns: 1fr;
        row-gap: 12px;
    }

    .servicesCard {
        padding: 16px 24px;
    }

    .servicesCardTitle {
        font-size: 18px;
    }
    
    .servicesCardContent {
        font-size: 14px;
        margin-top: 12px;
    }
}