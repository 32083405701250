.modalWrapper {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 1000;
}

.modalWindow {
    background-color: var(--main-white);
    position: absolute;
    left: 50%;
    top: 50%;
    translate: -50% -50%;
    padding: 30px;
    max-width: 520px;
    border-radius: 15px;
}

.modalFormTopRow {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.modalFormLogo {
    height: 48px;
    width: auto;
}

.modalFormCloseButton {
    background-color: transparent;
    border: none;
    height: 24px;
}

.modalFormCloseButton img {
    width: 100%;
    height: 100%;
}

.modalFormCloseButton:hover {
    cursor: pointer;
}

.modalFormInputs {
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-top: 30px;
}

.modalForm input[type="text"],
.modalForm input[type="email"],
.modalForm input[type="tel"] {
    border: none;
    padding: 0 10px;
    background-color: var(--main-white);
    border-bottom: 2px solid black;
    font-size: 24px;
}

.modalForm textarea {
    border: 2px solid black;
    padding: 10px;
    font-size: 24px;
    min-height: 160px;
}

.modalForm input[type="submit"] {
    margin-top: 30px;
    background-color: transparent;
    border: 2px solid black;
    color: black;
    font-size: 24px;
    padding: 10px 30px;
    border-radius: 30px;
    font-weight: var(--text-normal);
    width: 50%;
    align-self: center;
    transition: all 0.4s;
}

.modalForm input[type="submit"]:hover {
    background-color: black;
    color: var(--main-white);
    cursor: pointer;
}

.modalFormTitle {
    font-size: 40px;
    font-weight: var(--text-semiwide);
}

@media screen and (max-width: 600px) {
    .modalForm {
        width: 100vw;
        height: 100vh;
    }
}