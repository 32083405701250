@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@100;200;300;400;500;600;700&display=swap');

* {
  margin: 0;
  line-height: auto;
  font-family: 'Jost', sans-serif;
}


* {
  --main-black: #212121;
  --main-white: #FAFAFA;
  --secondary-black: #2C2C2C;
  --secondary-white: #E1E1E1;
  --text-wide: 600;
  --text-semiwide: 500;
  --text-normal: 400;
  --text-thin: 200;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  scroll-behavior: smooth;
}