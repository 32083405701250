.stepsSectionContainer {
    position: relative;
    color: var(--main-black);
    background-color: rgb(0, 0, 0);
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.3);
    background-image: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 30%, rgba(0,0,0,0) 60%, rgba(0,0,0,1) 100%);
    background-size: cover;
}

@-moz-document url-prefix() {
    .stepsSectionContainer {
        position: relative;
        color: var(--main-black);
        box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.5);
        background-image: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 30%, rgba(0,0,0,0) 60%, rgba(0,0,0,1) 100%), url('../images/background/steps-bg.png');
        background-size: 100%;
        background-position: center;
    }

    .stepsSectionBgVideo {
        display: none;
    }

    .stepsSectionOverlay {
        display: none;
    }
}

.stepsSectionOverlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: transparent;
    z-index: 1;
    opacity: 1;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-image: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 10%, rgba(0,0,0,0) 90%, rgba(0,0,0,1) 100%);
}

.stepsSectionBgVideo {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 0;
    opacity: 0.4;
    background-image: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 30%, rgba(0,0,0,0) 60%, rgba(0,0,0,1) 100%);
}

.stepsSectionContentWrapper {
    padding-top: 80px;
    padding-bottom: 80px;
    z-index: 2;
}

.stepsSectionTitle {
    font-size: 64px;
    color: var(--main-white);
    font-weight: var(--text-wide);
}

.stepsSectionSVGContainer {
    margin-top: 80px;
    position: relative;
    width: 100%;
}

svg {
    width: 100%;
    height: auto;
}

.stepsSectionSVG2,
.stepsSectionSVG3,
.stepsSectionSVG4 {
    display: none;
}

.stepsSetcionSVG1 {
    display: block;
}

@media screen and (max-width: 1440px) {
    .stepsSectionSVG1,
    .stepsSectionSVG3,
    .stepsSectionSVG4 {
        display: none;
    }

    .stepsSectionSVG2 {
        display: block;
    }
}

@media screen and (max-width: 810px) {

    .stepsSectionContainer {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .stepsSectionContentWrapper {
        padding-top: 0px;
        padding-bottom: 0px;
    }

    .stepsSectionTitle {
        font-size: 32px;
    }

    .stepsSectionSVGContainer {
        margin-top: 40px;
    };

    .stepsSectionSVG1,
    .stepsSectionSVG2,
    .stepsSectionSVG4 {
        display: none;
    }

    .stepsSectionSVG3 {
        display: block;
    }
}

@media screen and (max-width: 480px) {
    .stepsSectionSVG1,
    .stepsSectionSVG2,
    .stepsSectionSVG3 {
        display: none;
    }

    .stepsSectionSVG4 {
        display: block;
    }
}