.footerContainer {
    padding: 80px 0;
    background-color: black
}

.footerInfo {
    display: flex;
}

.footerLogoContainer {
    width: 35%;
}

.footerLogoContainer img {
    width: 80%;
    height: auto;
}

.footerMainInfo {
    width: 65%;
}

.footerMainInfoTop h2 {
    font-size: 24px;
    color: var(--main-white);
    font-weight: var(--text-wide);
}

.footerMainInfoTopLinks {
    margin-top: 40px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    row-gap: 24px;
}

.footerLink {
    font-size: 16px;
    color: var(--main-white);
    text-decoration: underline;
    font-weight: var(--text-normal);
    font-family: 'IBM Plex Mono', sans-serif;
}

.footerLink:hover {
    color: var(--secondary-white);
}

.footerMainInfoBottom {
    margin-top: 64px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
}

.footerMainInfoBottom p {
    font-size: 16px;
    color: var(--main-white);
    font-family: 'IBM Plex Mono', sans-serif;
    max-width: 65%;
}

.footerMainInfoBottomColumn h2 {
    color: var(--main-white);
    margin-bottom: 16px;
    font-weight: var(--text-wide);
}

.footerMainInfoBottomColumn {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.footerMainInfoBottomRow {
    display: flex;
    align-items: flex-start;
    gap: 16px;
    font-weight: var(--text-normal);
}

.footerMainInfoBottomRow img {
    width: 20px;
}

.footerMainInfoMap {
    margin-top: 64px;
    width: 100%;
    height: 540px;
    border-radius: 15px;
    overflow: hidden;
}

@media screen and (max-width: 1440px) {
    .footerMainInfoBottomColumn h2 {
        margin-bottom: 0;
    }

    .footerLogoContainer img {
        width: 100%;
    }
}

@media screen and (max-width: 900px) {
    .footerLogoContainer {
        display: none;
    }

    .footerMainInfo {
        width: 100%;
    }
}

@media screen and (max-width: 810px) {
    .footerContainer {
        padding: 20px 0;
    }
}

@media screen and (max-width: 600px) {
    .footerMainInfoTopLinks {
        grid-template-columns: 1fr;
        row-gap: 12px;
        margin-top: 16px;
    }

    .footerMainInfoBottom {
        margin-top: 20px;
        grid-template-columns: 1fr;
        row-gap: 20px;
    }

    .footerMainInfoBottomColumn {
        gap: 12px;
    }

    .footerMainInfoMap {
        height: 300px;
    }
}