.productsContainer {
    position: relative;
    padding-top: 120px;
    background-color: black;
    background-size: cover;
    background-position: center center;
    padding-bottom: 120px;
    overflow: hidden;
}

.productsOverlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-image: 
    linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 10%, rgba(0,0,0,0) 90%, rgba(0,0,0,1) 100%);
    z-index: 2;
}

.productsBg {
    position: absolute;
    width: 150vh;
    height: 150vh;
    left: 50%;
    top: 50%;
    filter: blur(70px);
    border-radius: 50%;
    /* background-image: radial-gradient(circle, rgba(56,36,103,0.4) 0%, rgba(0,0,0,0) 100%); */
    z-index: 1;
    translate: -50% -50%;
}

.productsTitle {
    position: relative;
    z-index: 3;
    font-size: 64px;
    color: var(--main-white);
    font-weight: var(--text-wide);
}

.productsCards {
    z-index: 3;
    position: relative;
    display: flex;
    margin-top: 80px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    column-gap: 100px;
    row-gap: 100px;
}

.productsCard {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 40px;
    background-color: transparent;
}

.productsCardLeft {
    position: relative;
    overflow: visible;
}

.productsCardLeftOverlay {
    position: absolute;
    border-radius: 50%;
    filter: blur(40px);
    width: 125%;
    height: 125%;
    top: 50%;
    left: 50%;
    translate: -50% -50%;
    background-image: radial-gradient(circle at center, rgba(103, 0, 208, 0.8) 0%, rgba(103, 0, 208, 0) 100%);
}

.productsCardLeft img {
    position: relative;
    z-index: 2;
    height: 64px;
    width: auto;
}

.productsCardRight {
    color: var(--main-white);
}

.productsCardRight h2 {
    font-size: 40px;
    line-height: 125%;
    font-weight: var(--text-semiwide);
}

.productsCardRight p {
    margin-top: 40px;
    font-size: 24px;
    font-family: 'IBM Plex Mono', sans-serif;
    font-weight: var(--text-normal);
}

/* adaptive 1440px */

@media screen and (max-width: 1440px) {

    .productsCardRight h2 {
        font-size: 32px;
    }

    .productsCardRight p {
        margin-top: 32px;
        font-size: 20px;
    }
}

@media screen and (max-width: 1024px) {

    .productsTitle {
        font-size: 32px;
    }

    .productsCards {
        grid-template-columns: 1fr;
    }

    .productsCardsRight p {
        line-height: 150%;
    }
}

@media screen and (max-width: 810px) {

    .productsContainer {
        padding-top: 20px;
        padding-bottom: 20px;
    }


    .productsCardRight h2 {
        font-size: 24px;
        line-height: 125%;
    }

    .productsCardRight p {
        margin-top: 12px;
        font-size: 14px;
    }
}

@media screen and (max-width: 480px) {


    .productsCardRight h2 {
        font-size: 18px;
    }

    .productsCardLeft img {
        height: 48px;
    }
}