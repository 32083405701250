.firstScreenContainer {
    width: min(1860px, 100%);
    padding: 0px 20px;
    position: absolute;
    left: 50%;
    top: 50%;
    translate: -50% -50%;
    text-align: left;
    display: flex;
    flex-direction: column;
}

.firstScreenTitle {
    width: 77%;
}

.firstScreenSubtitle {
    width: 70%;
    font-family: 'IBM Plex Mono', sans-serif;
}

.firstScreenImgWrapper {
    position: absolute;
    right: 0px;
    top: 50%;
    translate: 0px -50%;
    width: 33%;
    opacity: 0.9;
}

.firstScreenImgWrapper img {
    width: 100%;
}

.firstScreenTitle {
    letter-spacing: 0px;
    font-size: 64px;
    color: var(--main-white);
    font-weight: var(--text-semiwide);
}

.firstScreenTitleDiagram {
    font-weight: var(--text-wide);
}

.firstScreenSubtitle {
    font-size: 24px;
    margin-top: 40px;
    color: var(--main-white);
    font-weight: var(--text-normal);
    letter-spacing: 0px;
    font-family: 'IBM Plex Mono', sans-serif;
}

.firstScreenButtons {
    margin-top: 160px;
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
}

.firstScreenContactButton {
    border: 1px solid var(--main-white);
    background-color: transparent;
    color: var(--main-white);
    font-size: 24px;
    padding: 16px 20px;
    border-radius: 20px;
    font-weight: var(--text-wide);
    transition: all 0.4s;
    font-family: 'IBM Plex Mono', sans-serif;
    font-weight: 500;
}

.firstScreenContactButton:hover {
    background-color: var(--main-white);
    color: black;
    cursor: pointer;
}


/* adaptive 1440px */

@media screen and (max-width: 1440px) {
    .firstScreenImgWrapper {
        display: none;
    }

    .firstScreenTitle {
        width: 100%;
    }

    .firstScreenSubtitle {
        width: 80%;
    }

    .firstScreenButtons {
        margin-top: 100px;
    }
}

/* adaptive 1024px */

@media screen and (max-width: 1024px) {
    .firstScreenTitle {
        font-size: 48px;
    }
    
    .firstScreenButtons {
        gap: 24px;
    }
    
    .firstScreenContactButton {
        font-size: 18px;
    }
}

/* adaptive 810px */

@media screen and (max-width: 768px) {
    .firstScreenTitle {
        font-size: 32px;
    }

    .firstScreenSubtitle {
        font-size: 16px;
    }

    .firstScreenContactButton {
        padding: 12px 16px;
        font-size: 14px;
        border-radius: 10px;
    }
}

/* adaptive 480px */

@media screen and (max-width: 480px) {
    .firstScreenTitle {
        font-size: 24px;
        width: 100%;
    }

    .firstScreenSubtitle {
        margin-top: 16px;
        font-size: 14px;
        width: 100%;
    }

    .firstScreenButtons {
        margin-top: 40px;
    }
}